const hero = document.querySelector('.hero');

if(hero) {
    let popup = hero.querySelector('.video-popup');
    if(popup) {
        let watchBtn = hero.querySelector('.video-btn');
        let overlay = hero.querySelector('.video-overlay');
        let closeBtn = hero.querySelector('.close-popup');
        let iframe = hero.querySelector('iframe');
        let videoSrc = iframe.getAttribute('src');
    
        watchBtn.addEventListener('click', function(e) {
            e.preventDefault();
            if(popup.classList.contains('show-popup')) {
                closePopup();
            }
            else {
                if(iframe.getAttribute('src') == '') {
                    iframe.setAttribute('src', videoSrc);
                }
                popup.classList.add('show-popup');
                overlay.classList.add('show');
                document.querySelector('body').classList.add('no-scroll');
            }
        });
    
        closeBtn.addEventListener('click', function(e) {
            e.preventDefault();
            closePopup();
        });

        document.addEventListener('click', event => {
            const isClickInside = overlay.contains(event.target) || popup.querySelector('.popup-top').contains(event.target);
            if (isClickInside && popup.classList.contains('show-popup')) {
              closePopup();
            }
        });
        
        function closePopup() {
            popup.classList.remove('show-popup');
            overlay.classList.remove('show');
            document.querySelector('body').classList.remove('no-scroll');
            popup.querySelector('iframe').setAttribute('src', '');
        }
    }
}