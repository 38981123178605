let accordions = document.querySelectorAll('section.faq .faq-question');

if(accordions) {
    accordions.forEach(function(accordion, index) {
        accordion.addEventListener('click', function(e) {
            e.preventDefault();
            e.stopPropagation();

            let parent = accordion.parentNode;
            if(!parent.classList.contains('open')) {
                parent.classList.add('open');
                accordion.setAttribute('aria-expanded', 'true');
            }
            else {
                parent.classList.remove('open');
                accordion.setAttribute('aria-expanded', 'false');
            }
        });
    });
}

if (location.hash) {
    const accordion = document.querySelector('.faq-question' + location.hash);
    if (accordion) {
        accordion.parentNode.classList.add('open');
        accordion.setAttribute('aria-expanded', 'true');
    }
}