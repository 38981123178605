import { intersectionObserve } from "./_intersection-observer";

const elements = document.querySelectorAll('.flexible-content .text-and-image img');

intersectionObserve(elements, 0, 0.8, (element, intersectionRatio) => {
    (function(el) {
        if (intersectionRatio > 0 && !el.classList.contains('show')) {
            el.classList.add('show');
        } 
    })(element);
});