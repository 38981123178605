/*
*  new_map
*  This function will render a Google Map onto the selected jQuery element
*/


function new_map(el) {

    // var
    let markers = el.querySelectorAll('.marker');

    // vars
    let args = {
        center: new google.maps.LatLng(0, 0),
    };

    // create map
    let map = new google.maps.Map(el, args);

    // add a markers reference
    map.markers = [];

    // add markers
    markers.forEach(function(marker) {
        add_marker(marker, map);
    });

    // center map
    center_map(map);

    // return
    return map;
}

/*
*  add_marker
*  This function will add a marker to the selected Google Map
*/

function add_marker(item, map) {

    // var
    let latlng = new google.maps.LatLng(item.getAttribute('data-lat'), item.getAttribute('data-lng'));
  /*   let url = 'data:image/svg+xml;charset=UTF-8;base64,' + btoa('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 70.6 91.3" style="enable-background:new 0 0 70.6 91.3;" xml:space="preserve"><path fill="#DE0B1C" d="M35.3,0C15.8,0,0,15.8,0,35.3c0,28.5,35.3,56,35.3,56s35.3-26.5,35.3-56C70.6,15.8,54.8,0,35.3,0z M35.3,46.1c-7,0-12.8-5.7-12.8-12.8s5.8-12.7,12.8-12.7s12.8,5.7,12.8,12.8S42.3,46.1,35.3,46.1L35.3,46.1z"/></svg>');
    let icon = {
        url: url,
        scaledSize: new google.maps.Size(35, 35)
    }; */

    // create marker
    let marker = new google.maps.Marker({
        map: map,
        position: latlng,
        //icon: icon
    });

    // add to array
    map.markers.push(marker);

    marker.addListener('click', function(m) {
        let url = 'https://www.google.com/maps/dir/?api=1&destination=' + m.latLng.lat() + ',' + m.latLng.lng();
        window.open(url, '_blank');
    });
}

/*
*  center_map
*  This function will center the map, showing all markers attached to this map
*/

function center_map(map) {

    // vars
    let bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    map.markers.forEach(function(marker) {
        let latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
        bounds.extend(latlng);
    });

    // only 1 marker?
    if (map.markers.length == 1) {
        // set center of map
        map.setCenter(bounds.getCenter());
    }
    else {
        // fit to bounds
        map.fitBounds(bounds);
    }
}

/*
*  document ready
*  This function will render each map when the document is ready (page has loaded)
*/

let map = null;

let initMap = function() {

    document.querySelectorAll('.acf-map:not(.initialized)').forEach(function(item) {
        if (window.google === undefined || window.google.maps === undefined) {
            return;
        }

        // create map
        map = new_map(item);

        item.classList.add('initialized');
    });
    
};

window.initMap = initMap;