import 'slick-carousel/slick/slick';

function initSlider(id) {
    $('#' + id).slick({
        infinite: true,
        dots: false,
        autoplay: true,
        autoplaySpeed: 1,
        speed: 4000,
        slidesToShow: 10,
        slidesToScroll: 1,
        arrows: false,
        cssEase: 'linear',
        useTransform: true,
        responsive: [
            {
                breakpoint: 1999,
                settings: {
                    slidesToShow: 8,
                }
            },
            {
                breakpoint: 1599,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2.5,
                }
            },
        ]
    });
};
window.initSlider = initSlider;