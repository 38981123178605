const breakpoint = 1200;
let header = document.querySelector('header');

if(header) {
    const wrapperContent = header.querySelector('.wrapper').innerHTML;
    let headerHeight = header.offsetHeight;
    let lastScrollTop = 0;

    // Menu on scroll
    $(window).scroll(function (e) {
        let scrollTop = $(this).scrollTop();
        if ($(window).width() >= breakpoint && scrollTop <= 2) {
            header.classList.remove('sticky', 'show', 'animate');
        } 
        else if (scrollTop === 0) {
            header.classList.remove('sticky', 'show', 'animate');
        } 
        else if (scrollTop > headerHeight) {
            if (scrollTop < lastScrollTop) { // Scrolling up
                header.classList.add('sticky', 'animate', 'show')
            } else {
                header.classList.add('sticky');
                header.classList.remove('show');
            }
        }
        lastScrollTop = scrollTop;
    });

    if(window.innerWidth < breakpoint) {
        setMobileNav();
    }
    else {
        setDesktopNav();
    }

    window.addEventListener('resize', function() {
        
        header.querySelector('.wrapper').innerHTML = wrapperContent;

        if(window.innerWidth < breakpoint) {
            setMobileNav();
        }
        else {
            if(document.querySelector("body").classList.contains('no-scroll')) {
                document.querySelector("body").classList.remove('no-scroll');
            }
            setDesktopNav();
        }

    });

    function setMobileNav() {
        const button = document.querySelector(".menu-button");
        const navBar = document.querySelector("header .menu-content")
        const header = document.querySelector("header")
        const body = document.querySelector("body");
        const parent = document.querySelectorAll(".main-item.menu-item-has-children >a")
        const arrow = document.querySelectorAll(".main-item.menu-item-has-children >svg")

        button.onclick = function toggleMenu() {
            if (navBar.matches('.show')) {
                navBar.classList.remove("anim");
                setTimeout(function() {
                    navBar.classList.remove("show");
                }, 300);
                header.querySelector(".wrapper").classList.remove('anim');
                button.classList.remove("menu-close");
                header.classList.remove('show-menu');
                body.classList.remove('no-scroll');
            }
            else {
                navBar.classList.add("show");
                navBar.classList.add("anim");
                button.classList.add("menu-close");
                setTimeout(function() {
                    header.classList.add('show-menu');
                }, 300);
                header.querySelector(".wrapper").classList.add('anim');
                body.classList.add('no-scroll');

                // Hides dropdown when menu is hidden
                parent.forEach(function (ele) {
                    ele.classList.remove("show-ul");
                });
            }
        };


        //dropdown
        /**
         * check if parent has child with classname
         * on click add or remove class
        */
        parent.forEach(function (ele) {
            ele.addEventListener('click', function(e) {
                e.preventDefault();
                if (ele.parentNode.classList.contains('show-ul')) {
                    window.location = e.target.href;
                }
                else {
                    ele.parentNode.classList.add("show-ul");
                }
            });
        });
        arrow.forEach(function(ele) {
            ele.addEventListener('click', function(e) {
                e.preventDefault();
                if (ele.parentNode.classList.contains('show-ul')) {
                    ele.parentNode.classList.remove("show-ul");
                }
                else {
                    ele.parentNode.classList.add("show-ul");
                }
            });
        });
    }

    function setDesktopNav() {
        const header = document.querySelector("header");
        const body = document.querySelector("body");
        const parents = document.querySelectorAll('.menu-content > ul > li.main-item');
        let prevActive = false;
        let prevActiveElem = null;

        parents.forEach(parent => {
            parent.addEventListener('click', (event) => {
                prevActive = false;
                if(header.querySelector(".main-item.active")) {
                    prevActive = true;
                    prevActiveElem = header.querySelector(".main-item.active");
                }
                if(!parent.querySelector('.anim-wrapper').contains(event.target)) {
                    if (parent.classList.contains('active')) {
                        closeMenu(parent);
                    }
                    else {
                        if(prevActive) {
                            prevActiveElem.classList.add('fade');
                            setTimeout(function() {
                                prevActiveElem.classList.remove('active', 'fade');
                            }, 300);
                        }
                        parent.classList.remove('fade');
                        parent.classList.add('active');
                        header.classList.add('show-menu');
                        body.classList.add('no-scroll');
                    }
                }
            });
        });

        function closeMenu(elem) {
            if(elem) {
                elem.classList.remove('active');
            }
            else if(header.querySelector('.main-item.active')) {
                header.querySelector('.main-item.active').classList.remove('active');
            }
            header.classList.remove('show-menu');
            body.classList.remove('no-scroll');
        }

        document.addEventListener('click', event => {
            let isClickOutside = header !== event.target && !header.contains(event.target);
            if (isClickOutside && header.classList.contains('show-menu')) {
                closeMenu();
            }
        });
    }
}