let scrollOffset;
if(window.innerWidth < 1024) {
    scrollOffset = 50;
}
else {
    scrollOffset = 100;
}
// Smooth scroll to anchor links
$('a:not(.faq-question').click(function(e) {
    scrollToAnchor(this.href, e, scrollOffset);
});

function scrollToAnchor(href, event, offset) {
    if (href.length <= 1) {
        return;
    }

    let hashIndex = href.indexOf('#');

    if (hashIndex === -1) {
        return;
    }

    let id = href.substring(hashIndex + 1);

    if (!id) {
        return;
    }

    let $el = $('#' + id);

    if ($el.length === 0){
        return;
    }

    $('html, body').animate({
        scrollTop: $el.offset().top - (offset || 0)
    }, 300);

    if (event) {
        event.preventDefault();
    }
    if(document.querySelector('header') && document.querySelector('header').classList.contains('show-menu')) {
        document.querySelector('header').classList.remove('show-menu');
        document.querySelector('body').classList.remove('no-scroll');
        if(document.querySelector('header .main-item.active')) {
            document.querySelector('header .main-item.active').classList.remove('active');
        }
        if(document.querySelector("header .menu-content.anim")) {
            document.querySelector("header .menu-content").classList.remove("anim");
            setTimeout(function() {
                document.querySelector("header .menu-content").classList.remove("show");
            }, 300);
            document.querySelector('header .wrapper').classList.remove('anim');
            document.querySelector(".menu-button").classList.remove("menu-close");
            document.querySelector('header .menu-content li.show-ul').classList.remove('show-ul');
        }
    }
}

window.scrollToAnchor = scrollToAnchor;

if (window.location.hash) {
    let target = window.location.hash.replace('#', '');
    window.location.hash = "";
    setTimeout(function () {
        $('html,body').animate({
            scrollTop: $("#" + target).offset().top - scrollOffset
        }, 300);
    }, 900)
}