import lottie from 'lottie-web';
import { intersectionObserve } from "./_intersection-observer";

const animations = document.querySelectorAll('.animation');
let animation;
let index = 0;

if (animations) {

    function setup() {
        animations.forEach(element => loadAnimation(element));
    }
    
    setup();
    setTimeout(setup, 1000);
    setTimeout(setup, 3000);

    function loadAnimation(element) {
        if (element.classList.contains('loaded')) {
            return;
        }

        element.classList.add('loaded');

        if (element.getAttribute('data-anim-name') === null) {
            element.setAttribute('data-anim-name', 'anim-icon-' + index);
            animation = lottie.loadAnimation({
                container: element, // Required
                animationData: JSON.parse(element.querySelector('.data code').innerHTML),
                renderer: 'svg', // Required
                loop: true, // Optional
                autoplay: false, // Optional
                name: "anim-icon-" + index, // Name for future reference. Optional.
            });
            element.classList.add('animate');
            animation.play(element.getAttribute('data-anim-name'));
            index++;
        }
    }

    intersectionObserve(animations, 0, 0.5, (element, intersectionRatio) => {
        (function(el) {
            if (intersectionRatio > 0 && !el.classList.contains('show')) {
                el.classList.add('show');
            } 
        })(element);
    });
}