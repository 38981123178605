const dashboard = document.querySelector('body.page-template-page-custom-dashboard');

if(dashboard) {
    const form = dashboard.querySelector('.support-form form');
    form.addEventListener('submit', function(e) {
        e.preventDefault();
  /*       let textValue = form.querySelector('textarea').value;
        let responseElem = form.querySelector('.response');
        responseElem.classList.remove('success', 'error');
        responseElem.style.display = 'none';
        let data = new FormData();
        data.append('action', 'submit_support_form');
        data.append('msg', textValue);

        form.querySelector('button').classList.add('wait');

        $.ajax({
            url: project_scripts.ajax_url,
            type: 'POST',
            data: data,
            cache: false,
            processData: false,
            contentType: false,
            success: function(data) {
                let json = JSON.parse(data);
                let message = json['message'];
                if (json.status === 'success') {
                    responseElem.classList.add('success');
                } else {
                    responseElem.classList.add('error');
                }
                responseElem.innerText = message;
                responseElem.style.display = 'block';
                form.reset();
                form.querySelector('button').classList.remove('wait');
            },
            error: function(data) {
                responseElem.classList.add('error');
                responseElem.innerText = data.responseText;
            }
        }); */
    });
}