const tutorialButtons = document.querySelectorAll(".tutorial-button");
const tutorialContent = document.querySelectorAll(".tutorial-item");

if(tutorialButtons) {
    tutorialButtons.forEach(item => {
        item.addEventListener("click", function (event) {
            if(!item.classList.contains("active")) {
                const tabName = event.target.classList[1];
                const tutorialButton = document.querySelectorAll(".tutorial-button.active");
                tutorialContent.forEach(element => {
                    if(element.classList.contains(tabName)) {
                        element.classList.add("active")
                    } else {
                        element.classList.remove("active")
                    }
                });
                item.classList.add("active");
                tutorialButton[0].classList.remove("active")
            }
        })
    });
}

